import Link from "next/link";
import Head from "next/head";
import { set_statusbar } from "../lib/statusbar";
import UserIcon from "../components/icons/usericon";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { is_logged_in } from "../lib/auth";
// import dynamic from 'next/dynamic'
import LogoText from "../components/icons/logotext";
import LogoIcon from "../components/icons/logoicon";

function IndexPage() {
    const router = useRouter();

    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const admin = useSelector((state) => state.admin);

    const { theme } = useTheme();
    set_statusbar("index");

    const { version } = require("../package.json");

    const splash_screens = [
        {
            backgroundImage: "url(/assets/splash/splash1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        {
            backgroundImage: "url(/assets/splash/splash2.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        {
            backgroundImage: "url(/assets/splash/splash3.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        {
            backgroundImage: "url(/assets/splash/splash4.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        {
            backgroundImage: "url(/assets/splash/splash5.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        {
            backgroundImage: "url(/assets/splash/splash6.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
    ];

    const get_splash_screen = () => {
        return splash_screens.sort(() => 0.5 - Math.random())?.[0];
    };

    // useEffect(() => {
    if (auth && (user || admin)) {
        set_statusbar(theme);
        // if we are onboarded, dashboard us .
        if (
            (user.onboarded && user.onboarded == 1) ||
            (admin.onboarded && admin.onboarded == 1)
        ) {
            router.push("/dashboard");
            // router.push('/onboard');
        } else if (
            !user.onboarded ||
            user.onboarded == 0 ||
            !admin.onboarded ||
            admin.onboarded == 0
        ) {
            // if we arent onboarded yet, onboard us
            router.push("/onboard");
        }
    }
    // }, [auth, user, admin])

    const [data, set_data] = useState(get_splash_screen());

    useEffect(() => {
        const interval = setInterval(() => {
            set_data(get_splash_screen());
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Head>
                <title>Shadow Detect</title>
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash1.jpg"
                />
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash2.jpg"
                />
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash3.jpg"
                />
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash4.jpg"
                />
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash5.jpg"
                />
                <link
                    rel="preload"
                    as="image"
                    href="/assets/splash/splash6.jpg"
                />
            </Head>

            {!is_logged_in() ? (
                <div className="shadowdetect-wrapper-index " style={data}>
                    <section className="content w-full flex flex-grow flex-col justify-center">
                        <div className="shadowdetect-content">
                            <div className="w-full gradient-logo p-6">
                                <LogoText />
                            </div>
                            <div className="w-64 mx-auto gradient-logo-animated py-8">
                                <LogoIcon />
                            </div>
                            <div className="w-96 max-w-full text-center">
                                <Link href="/auth/login">
                                    <span className="mt-5 btn btn-primary btn-sm btn-block text-left">
                                        <i className="mr-3">
                                            <UserIcon />
                                        </i>
                                        Login
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </section>
                    <footer className="text-white">{version}</footer>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default IndexPage;
